import React from 'react';
import { FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';

// Berechnung des Alters
const calculateAge = (birthdate) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  
  // Falls der Geburtstag in diesem Jahr noch nicht stattgefunden hat, 1 Jahr abziehen
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
};

const PersonCard = ({ person }) => {
  const age = calculateAge(person.birthdate);

  return (
    <div className="flex flex-col items-center bg-white p-10 rounded-lg shadow-lg transition-transform duration-500 transform">
      {/* Bild nur anzeigen, wenn es vorhanden ist */}
      {person.image && (
        <img
          className="w-40 h-40 rounded-full mb-6"
          src={person.image}
          alt={`${person.name}'s profile`}
        />
      )}
      <h2 className="text-2xl font-semibold text-gray-800">{person.name}</h2>
      <p className="text-gray-500">{age} Jahre</p> {/* Alter anzeigen */}
      <p className="text-gray-700 mb-4">{person.job}</p>
      
      {/* Soziale Medien nur anzeigen, wenn die Links vorhanden sind */}
      <div className="flex space-x-4 mt-4">
        {person.instagram && (
          <a href={person.instagram} className="text-gray-600 hover:text-gray-900">
            <FaInstagram size={24} />
          </a>
        )}
        {person.facebook && (
          <a href={person.facebook} className="text-gray-600 hover:text-gray-900">
            <FaFacebook size={24} />
          </a>
        )}
        {person.linkedin && (
          <a href={person.linkedin} className="text-gray-600 hover:text-gray-900">
            <FaLinkedin size={24} />
          </a>
        )}
      </div>

      {/* Website nur anzeigen, wenn vorhanden */}
      {person.website && (
        <div className="mt-6">
          <a href={person.website} className="bg-gray-700 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-gray-700 transition-colors">
            <button>
              Website
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

export default PersonCard;
