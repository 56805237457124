import React, { useState, useEffect } from 'react';

function CookieBanner() {
    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        // Prüfen, ob der Cookie-Banner bereits akzeptiert wurde
        const accepted = localStorage.getItem('cookieBanner');
        if (accepted) {
            setShowBanner(false);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieBanner', 'accepted');
        setShowBanner(false);
    };

    const handleDecline = () => {
        localStorage.setItem('cookieBanner', 'declined');
        setShowBanner(false);
    };

    if (!showBanner) {
        return null; // Versteckt den Banner, wenn Cookies akzeptiert/abgelehnt wurden
    }

    return (
        <div className="fixed bottom-0 w-full bg-black text-white p-4 flex justify-between items-center" style={{ boxShadow: '10px 1px 20px white' }}>
            <div className="flex items-center">
                <span className="text-2xl mr-2">🍪</span> {/* Cookie Emoji */}
                <span>Diese Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erhalten.</span>
            </div>
            <div className="space-x-2">
                <button
                    onClick={handleDecline}
                    className="border border-white text-white py-2 px-4 rounded hover:bg-white hover:text-black"
                >
                    Cookies ablehnen
                </button>
                <button
                    onClick={handleAccept}
                    className="bg-white hover:bg-black text-black py-2 px-4 rounded"
                >
                    Cookies akzeptieren
                </button>
            </div>
        </div>
    );
}

export default CookieBanner;
