import React from 'react';

function Header() {
    return (
        <div className="header fixed top-0 w-full bg-black flex justify-center items-center">
            <div className="logo">
                <img 
                  src="/logo.png" 
                  alt="Logo" 
                  className="w-[115px] h-[115px]" 
                />
            </div>
        </div>
    );
}

export default Header;
