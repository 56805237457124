import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="footer w-full bg-black text-white py-4 flex justify-center items-center">
            <div className="links space-x-8">
                <Link to="/Impressum" className="hover:underline">Impressum</Link>
                <Link to="/Datenschutz" className="hover:underline">Datenschutz</Link>
            </div>
        </div>
    );
}

export default Footer;
