import React from 'react';
import { useNavigate } from 'react-router-dom';

function Impressum() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/'); // Zurück zur Hauptseite (Carousel)
    };

    return (
        <div className="min-h-screen bg-black text-white flex flex-col p-8 overflow-y-auto">
            {/* Back Button */}
            <button
                onClick={goBack}
                className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600 mb-4 self-start"
            >
                ← Zurück
            </button>

            <h1 className="text-4xl font-bold mb-8">Impressum</h1>

            <p className="mb-4">
                Angaben gemäß § 5 TMG:
            </p>

            <p className="mb-4">
                Musterfirma GmbH<br />
                Musterstraße 1<br />
                12345 Musterstadt<br />
                Deutschland<br />
                Telefon: +49 123 456789<br />
                E-Mail: info@musterfirma.de<br />
            </p>

            <h2 className="text-2xl font-bold mb-4">Vertretungsberechtigter Geschäftsführer:</h2>
            <p className="mb-4">
                Max Mustermann
            </p>

            <h2 className="text-2xl font-bold mb-4">Registereintrag:</h2>
            <p className="mb-4">
                Eingetragen im Handelsregister beim Amtsgericht Musterstadt unter der Nummer HRB 12345
            </p>

            <h2 className="text-2xl font-bold mb-4">Umsatzsteuer-ID:</h2>
            <p className="mb-4">
                Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE123456789
            </p>

            <h2 className="text-2xl font-bold mb-4">Haftungsausschluss:</h2>
            <p className="mb-4">
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit
                und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
            </p>

            <h2 className="text-2xl font-bold mb-4">Haftung für Links:</h2>
            <p className="mb-4">
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb können wir für diese fremden Inhalte keine Gewähr übernehmen.
            </p>

            <h2 className="text-2xl font-bold mb-4">Urheberrecht:</h2>
            <p className="mb-4">
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            </p>
        </div>
    );
}

export default Impressum;
