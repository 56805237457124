import React, { useState } from 'react';
import PersonCard from './PersonCard';

const persons = [
  {
    name: 'Tobias Gager',
    birthdate: '2006-06-26',
    job: 'Schüler - Betriebsinformatik',
    image: '/tobiasfoto.png',
    instagram: 'https://instagram.com/tobias.gager',
    facebook: 'https://www.facebook.com/tobias.gager.9',
    linkedin: 'https://www.linkedin.com/in/tobias-gager/',
    website: 'https://tobias.gager.work',
  },
  {
    name: 'Michael Gager',
    birthdate: '1974-05-14',
    job: 'Personal & Nachhaltigkeitsmanagement',
    image: '/michaelfoto.jpeg',
    instagram: 'https://www.instagram.com/michael.gager/',
    facebook: 'https://facebook.com',
    linkedin: 'https://linkedin.com',
    website: 'https://michael.gager.work',
  },
  {
    name: 'Daniela Gager',
    birthdate: '1976-09-14',
    job: 'Bürokauffrau - Mietgeschäft',
    image: 'https://via.placeholder.com/150',
    instagram: 'https://www.instagram.com/gagerdaniela/',
    website: 'https://daniela.gager.work',
  },
  {
    name: 'Luca Gager',
    birthdate: '2008-01-30',
    job: 'Lehre - Tischler',
    image: '/lucafoto.jpg',
    instagram: 'https://www.instagram.com/lgager13/',
    website: 'https://luca.gager.work',
  },
  {
    name: 'Adrian Gager',
    birthdate: '2012-07-07',
    job: 'Content Creator',
    image: '/adrianfoto.png',
    website: 'https://adrian.gager.work',
  },
];

const PersonCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevPerson = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? persons.length - 1 : prevIndex - 1
    );
  };

  const nextPerson = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === persons.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getCardStyle = (index) => {
    const position = (index - currentIndex + persons.length) % persons.length;

    if (position === 0) {
      return {
        transform: 'translateX(0) scale(1)',
        zIndex: 50,
        opacity: 1,
      };
    } else if (position === 1) {
      return {
        transform: 'translateX(200px) scale(0.8)',
        zIndex: 40,
        opacity: 0.8,
      };
    } else if (position === 4) {
      return {
        transform: 'translateX(-200px) scale(0.8)',
        zIndex: 40,
        opacity: 0.8,
      };
    } else {
      return {
        transform: 'translateX(0) scale(0.5)',
        zIndex: 30,
        opacity: 0,
      };
    }
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto mt-10 flex items-center justify-center">
      {persons.map((person, index) => (
        <div
          key={index}
          className="absolute transition-transform duration-500"
          style={getCardStyle(index)}
        >
          <PersonCard person={person} />
        </div>
      ))}

      {/* Left Arrow */}
      <div className="absolute top-1/2 transform -translate-y-1/2 left-4 z-50">
        <button
          className="text-white text-4xl p-3 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 focus:outline-none transition-colors"
          onClick={prevPerson}
        >
          &#8592;
        </button>
      </div>

      {/* Right Arrow */}
      <div className="absolute top-1/2 transform -translate-y-1/2 right-4 z-50">
        <button
          className="text-white text-4xl p-3 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 focus:outline-none transition-colors"
          onClick={nextPerson}
        >
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default PersonCarousel;
