import React from 'react';
import { useNavigate } from 'react-router-dom';

function Datenschutz() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/'); // Zurück zur Hauptseite (Carousel)
    };

    return (
        <div className="min-h-screen bg-black text-white flex flex-col p-8 overflow-y-auto">
            {/* Back Button */}
            <button
                onClick={goBack}
                className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600 mb-4 self-start"
            >
                ← Zurück
            </button>

            <h1 className="text-4xl font-bold mb-8">Datenschutzerklärung</h1>

            <p className="mb-4">
                Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Nachfolgend informieren wir Sie über die
                Erhebung und Verarbeitung Ihrer personenbezogenen Daten bei der Nutzung unserer Website.
            </p>

            <h2 className="text-2xl font-bold mb-4">1. Verantwortliche Stelle</h2>
            <p className="mb-4">
                Verantwortliche Stelle im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:
            </p>
            <p className="mb-4">
                Musterfirma GmbH<br />
                Musterstraße 1<br />
                12345 Musterstadt<br />
                Deutschland<br />
                E-Mail: info@musterfirma.de<br />
            </p>

            <h2 className="text-2xl font-bold mb-4">2. Erhebung und Verarbeitung von Daten</h2>
            <p className="mb-4">
                Wir erheben und verarbeiten personenbezogene Daten nur, wenn dies gesetzlich erlaubt ist oder Sie in
                die Erhebung und Verarbeitung einwilligen.
            </p>

            <h3 className="text-xl font-semibold mb-2">2.1. Daten, die wir erheben</h3>
            <p className="mb-4">
                Beim Besuch unserer Website werden folgende Daten erhoben: IP-Adresse, verwendeter Browser,
                Betriebssystem und Uhrzeit des Zugriffs.
            </p>

            <h2 className="text-2xl font-bold mb-4">3. Rechte der betroffenen Personen</h2>
            <p className="mb-4">
                Sie haben das Recht auf Auskunft über die Verarbeitung Ihrer personenbezogenen Daten, sowie auf
                Berichtigung, Löschung oder Einschränkung der Verarbeitung.
            </p>

            <p className="mb-4">
                Wenn Sie Fragen zum Datenschutz haben, können Sie uns jederzeit unter der oben angegebenen Adresse
                kontaktieren.
            </p>
        </div>
    );
}

export default Datenschutz;
